/* eslint-disable react/prop-types */
/* eslint-disable no-case-declarations */
import React from 'react';
import { graphql } from 'gatsby';

import LayoutMain from 'layouts/layout-main';
import Hero from 'components/pages/integrations-detail/hero';
import Content from 'components/pages/integrations-detail/content';

import { useLazyBlocks } from 'hooks/use-lazy-blocks';

const IntegrationsDetail = ({ data: { wpIntegration: data }, pageContext, location }) => {
  const { reactedContent } = useLazyBlocks(data.content);
  return (
    <LayoutMain
      seo={{ ...data.seo, date: pageContext.date }}
      headerTheme={data.acf.headerTheme}
      footerTheme={data.acf.footerTheme}
      withBanner={pageContext.showBanner}
      withBottomOffset
      pageContext={pageContext}
      location={location}
    >
      <Hero
        title={data.acf.heroIntegration.title}
        buttonText={data.acf.heroIntegration.buttonText}
        buttonUrl={data.acf.heroIntegration.buttonUrl}
      />
      <Content content={reactedContent} />
    </LayoutMain>
  );
};

export default IntegrationsDetail;

export const query = graphql`
  query ($id: String!) {
    wpIntegration(id: { eq: $id }) {
      title
      content
      acf {
        headerLayout
        headerTheme
        footerTheme
        heroIntegration {
          title
          buttonText
          buttonUrl
        }
      }
      ...wpIntegrationSeo
    }
  }
`;
