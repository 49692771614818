import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Heading from 'components/shared/heading';
import Button from 'components/shared/button';

import styles from './hero.module.scss';

const cx = classNames.bind(styles);

const Hero = ({ title, buttonText, buttonUrl }) => (
  <section className={cx('wrapper')}>
    <div className={cx('container', 'inner')}>
      <Heading className={cx('title')} size="xxl">
        {title}
      </Heading>
      {buttonText && buttonUrl && (
        <Button to={buttonUrl} theme="accent-primary" size="lg">
          {buttonText}
        </Button>
      )}
    </div>
  </section>
);

Hero.defaultProps = {
  buttonText: null,
  buttonUrl: null,
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
};

export default Hero;
